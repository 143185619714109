import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: {
      title: "欢迎光临",
    },
    redirect: '/index'
  },
  {  // 用户ip授权登录
    path: '/index',
    name: 'index',
    meta: {
      title: "欢迎光临",
    },
    component: () => import('../views/index.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
